/* eslint-disable */
import React from "react";
import IconBG from "../../content/assets/background.svg";
import Logo from "../../content/assets/logo.svg";
import { useIntl } from "react-intl";
import MobileMenu from "./MobileMenu"
import { motion } from "framer-motion";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Turkish from "../../content/assets/turkey.svg"
import English from "../../content/assets/usa.svg"

const Layout = props => {
  const intl = useIntl();
  const { title, children } = props;
  return (
    <>
      <div className="absolute">
        <Link to="/" language="en" >
          <motion.div
            className="padding mobileNone"
            whileHover={{ scale: 1.1 }}
            transition={{ ease: "easeOut", duration: 0.4 }}

          >
            <English className="langSVG" />
          </motion.div>
        </Link>
        <Link to="/" language="tr" >
          <motion.div
            className="padding mobileNone"
            whileHover={{ scale: 1.1 }}
            transition={{ ease: "easeOut", duration: 0.4 }}

          >
            <Turkish className="langSVG" />
          </motion.div>
        </Link>
      </div>
    <div className="site-wrapper">

      <header className="site-head">
        <div className="site-head-container">
          <nav id="swup" class="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>{intl.formatMessage({ id: "home" })}</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/about`}>{intl.formatMessage({ id: "nav1" })}</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/repertoire`}>{intl.formatMessage({ id: "nav2" })}</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/news`}>{intl.formatMessage({ id: "nav3" })}</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/videos`}>{intl.formatMessage({ id: "nav7" })}</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/gallery`}>{intl.formatMessage({ id: "nav5" })}</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <Logo className="site-head-logo" />
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://open.spotify.com/artist/0bfOHqirNeXsaAoSdNSiBF?si=vmftc6uDQqaMrl2fMIsfLw"
                title="Spotify"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spotify
              </a>
              <a
                href="https://instagram.com/ilyunburkev"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/ilyun.burkev.9"
                title="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://youtube.com/c/ilyunburkev"
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              <li className="nav-elements" role="menuitem">
                <Link to={`/contact`}>{intl.formatMessage({ id: "nav6" })}</Link>
              </li>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu />

      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> All rights reserved. <br /> &mdash;
        <br />Design by{" "}
        <a
          href="https://locksbridge.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          LocksBridge Artist Management
        </a>
      </footer>
      <IconBG id="background-svg" />
    </div>
      </>
  );
};

export default Layout;
