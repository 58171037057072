import React, { useState } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import Logo from "../../content/assets/logo.svg";
import { useIntl } from "react-intl";
import English from "../../content/assets/usa.svg";
import Turkish from "../../content/assets/turkey.svg";

const Header = styled.header`
  background: ${props => (props.open ? "#323232" : "transparent")};
  transition: max-height 0.5s cubic-bezier(0.52, 0.16, 0.24, 1), border 0.3s;
  position: ${props => (props.open ? "fixed" : "absolute")};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: ${props => (props.open ? "100vh" : "0")};
  width: 100%;
  z-index: 99;
  border-width: ${props => (props.open ? "0" : "1px")};
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
const Nav = styled.nav`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    padding: 0 3em;
    display: block;
  }
`;

const List = styled.ul`
  position: relative;
  padding: 0;
  pointer-events: ${props => (props.open ? "cursor" : "none")};
`;

const Item = styled(motion.li)`
  display: flex;
  justify-content: center;
  padding: 0.2em 0;
  font-size: 1.1em;
  top: 1rem;
  &:first-of-type {
    padding: 0;
    pointer-events: auto;
    opacity: 1 !important;
    position: absolute;
    height: 60px;
    left: 2rem;
    top: 1rem;
    margin: 0;
    @media screen and (min-width: 1024px) {
      position: relative;
      left: 0;
      margin: 0;
      grid-column: 1 / span 8;
      justify-self: start;
    }
  }
  a {
    position: relative;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      color: #f5f5f5;
    }
    @media (hover: none) {
      color: #f5f5f5 !important;
    }
  }
`;

const Toggle = styled.div`
  margin: 0;
  padding: 0;
  z-index: 999;
  transition: transform 0.5s;
  cursor: pointer;
  position: absolute;
  top: 3rem;
  bottom: 0;
  right: 3rem;
  width: 2rem;
  height: 60px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
  span {
    transition: all 0.3s;
    display: block;
    background: #fff;
    width: 100%;
    height: 2px;
  }
  span:first-of-type {
    transform: rotate(${props => (props.open ? "45deg" : "0")})
      translateY(${props => (props.open ? "0" : ".35rem")});
  }
  span:nth-of-type(2n) {
    transform: rotate(${props => (props.open ? "-45deg" : "0")})
      translateY(${props => (props.open ? "0" : "-.35rem")});
    position: relative;
    bottom: ${props => (props.open ? "2px" : "0")};
  }
`;

const Anchor = styled(motion.a)`
  opacity: 0.7;
  font-style: oblique;
  font-size: 0.9em;
`

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  function toggle() {
    setIsOpen(!isOpen);
    document.documentElement.classList.toggle("contain");
  }

  function close() {
    setIsOpen(false);
    document.documentElement.classList.remove("contain");
  }

  const itemVariants = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.3,
        staggerChildren: 1
      }
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.7,
      }
    }
  };

  return (
    <Header open={isOpen}>
      <Nav>
        <Toggle open={isOpen} onClick={toggle} aria-label="Toggle Menu">
          <span />
          <span />
        </Toggle>
        <Item>
          <Link to="/">
            <Logo className="site-head-logo" />
          </Link>
        </Item>
        <List open={isOpen}>
          <Item>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
              <Link to="/" onClick={close}>{intl.formatMessage({ id: "home" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Link to="/about" onClick={close}>{intl.formatMessage({ id: "nav1" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Link to="/repertoire" onClick={close}>{intl.formatMessage({ id: "nav2" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Link to="/news" onClick={close}>{intl.formatMessage({ id: "nav3" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Link to="/videos" onClick={close}>{intl.formatMessage({ id: "nav7" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Link to="/gallery" onClick={close}>{intl.formatMessage({ id: "nav5" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Link to="/contact" onClick={close}>{intl.formatMessage({ id: "nav6" })}</Link>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Anchor
              href="https://open.spotify.com/artist/0bfOHqirNeXsaAoSdNSiBF?si=vmftc6uDQqaMrl2fMIsfLw"
              title="Spotify"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              Spotify
            </Anchor>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Anchor
              href="https://instagram.com/ilyunburkev"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              Instagram
            </Anchor>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Anchor
              href="https://www.facebook.com/piyanistilyunburkev"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              Facebook
            </Anchor>
          </Item>
          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
            <Anchor
              href="https://youtube.com/c/ilyunburkev"
              title="YouTube"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              YouTube
            </Anchor>
          </Item>

            <Item
              initial={false}
              variants={itemVariants}
              animate={isOpen ? "open" : "closed"}
            >
              <Link to="/" language="en" onClick={close}>
              <English className="langSVG"/>
              </Link>
            </Item>


            <Item
              initial={false}
              variants={itemVariants}
              animate={isOpen ? "open" : "closed"}
            >
              <Link to="/" language="tr" onClick={close}>
              <Turkish className="langSVG"/>
              </Link>
            </Item>

          <Item
            initial={false}
            variants={itemVariants}
            animate={isOpen ? "open" : "closed"}
          >
          </Item>
        </List>
      </Nav>

    </Header>
  );
};

export default Menu;
